import { ArrowAlignLeftIcon, ArrowAlignRightIcon, GearIcon, PeopleIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { LargeModelViewer } from '~/features/files/components/large-model-viewer';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Queries } from '~/shared/hooks/queries';
import type { ExtensionLoadingState } from '~/shared/hooks/use-collaboration-extension';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';
import { FollowingBorder } from './following-border';
import { ViewerPanels } from './viewer-panels';

interface Props {
  projectId: string;
  workshopId: string;
  workshop: ReturnType<typeof Queries.Workshops.useFindWorkshopById>;
}

export function ViewingRoom({ workshop, ...props }: Props) {
  const $env = Oasis.Env.useStore();
  const $workshopAudience = useWorkshopAudience(props.workshopId);

  const [activePanel, setActivePanel] = useState<string | undefined>();
  const [collabExtension, setCollabExtension] = useState<any>();
  const [collapsedNav, setCollapsedNav] = useState(true);
  const [lockCamera, setLockCamera] = useState(true);

  const activeModelVersionId = Queries.Workshops.useGetActiveModelVersionId(props.workshopId);
  const hostId = workshop.data?.userId;

  useEffect(() => {
    let handleDisconnect = () => {
      Oasis.Voice.disconnect();
    };

    Oasis.Voice.connectToWorkshop({ workshopId: props.workshopId }).then(result => {
      if (result.ok) {
        handleDisconnect = result.value.disconnectFromWorkshop;
      }
    });

    return handleDisconnect;
  }, [props.workshopId]);

  const hostDeviceId = $workshopAudience.hostUser?.deviceId;

  useEffect(() => {
    if (collabExtension && hostDeviceId) {
      collabExtension.startFollow(hostDeviceId);
      return () => collabExtension.startFollow(null);
    }
  }, [hostId, collabExtension, hostDeviceId]);

  const onClose = useCallback(() => {
    setActivePanel(undefined);
  }, [setActivePanel]);

  const onCollabExtStateChange = useCallback(
    (state: ExtensionLoadingState, extension?: any) => {
      switch (state) {
        case 'INITIAL':
        case 'LOADING':
          break;
        case 'LOADED':
          setCollabExtension(extension);
          break;
        case 'FAILED':
          NotificationManager.push({
            status: 'error',
            content: 'Collaboration extension failed to load',
          });
          break;
        default:
          state satisfies never;
      }
    },
    [setCollabExtension]
  );

  if (workshop.isLoading) {
    return null;
  }

  return (
    <section className="flex flex-1">
      <nav
        className={clsx(
          'flex flex-col h-full bg-charcoal-50 border-r border-charcoal-100',
          collapsedNav ? 'min-w-[3.25rem] max-w-[3.25rem]' : 'min-w-[13.75rem]'
        )}
      >
        {[
          [
            // { name: 'sheets', icon: <SheetsIcon />, disabled: true },
            // { name: 'levels', icon: <LevelsIcon />, disabled: true },
            // { name: 'issues', icon: <CheckmarkCircleIcon />, disabled: true },
            // { name: 'view-points', icon: <BookmarkIcon />, disabled: true },
            // { name: 'model-browser', icon: <ModelBrowserIcon />, disabled: true },
          ],
          [
            // { name: 'model-browser', icon: <ModelBrowserIcon />, disabled: true }, // move back up eventually
            // { name: 'components', icon: <ComponentsIcon />, disabled: true },
            { name: 'users', label: 'People', icon: <PeopleIcon />, disabled: false },
            // { name: 'share', icon: <ShareIcon />, disabled: true },
            { name: 'settings', label: 'Settings', icon: <GearIcon />, disabled: false },
          ],
        ].map((tools, index) => (
          <Fragment key={index}>
            {/* {index > 0 && <hr className="border-charcoal-100 w-6 my-6 mx-auto" />} */}
            <ul>
              {tools.map(tool => (
                <li key={tool.name}>
                  <button
                    disabled={tool.disabled}
                    className={clsx(
                      'flex items-center w-full h-12 text-lg border-l-2 cursor-pointer pl-3',
                      activePanel === tool.name
                        ? 'bg-white text-blue-500 border-blue-500'
                        : 'text-charcoal-700 hover:text-blue-500 border-transparent'
                    )}
                    onClick={() => setActivePanel(tool.name)}
                  >
                    {tool.icon}
                    {!collapsedNav && <span className="ml-3">{tool.label}</span>}
                  </button>
                </li>
              ))}
            </ul>
          </Fragment>
        ))}

        <div className="mt-auto">
          <div className="mb-2 border-t border-charcoal-200 mx-2" />
          <button
            onClick={() => setCollapsedNav(!collapsedNav)}
            className="ml-1 mb-2 rounded hover:bg-charcoal-100 self-start"
          >
            <Tooltip placement="right" content={collapsedNav ? 'Expand' : 'Collapse'}>
              <div className="p-2">{collapsedNav ? <ArrowAlignRightIcon /> : <ArrowAlignLeftIcon />}</div>
            </Tooltip>
          </button>
        </div>
      </nav>
      <div className={clsx('adsk-web-viewer relative flex flex-1', lockCamera && 'camera-locked')}>
        {activeModelVersionId.data && (
          <LargeModelViewer
            versionId={activeModelVersionId.data}
            onCollabExtStateChange={onCollabExtStateChange}
            isCollaborativeWebViewer
          />
        )}

        {$env.isDevMode && (
          <button className="absolute bottom-2 right-2 w-28 text-center" onClick={() => setLockCamera(!lockCamera)}>
            <p className="ml-auto text-[10px] uppercase text-yellorange-500 leading-tight">Dev mode only</p>
            <p className="text-yellorange-500">{lockCamera ? 'Unlock camera' : 'Lock camera'}</p>
          </button>
        )}

        <div className="absolute top-0 left-0 bottom-0 flex">
          {activePanel && (
            <ViewerPanels
              projectId={props.projectId}
              workshopId={props.workshopId}
              activePanel={activePanel}
              onClose={onClose}
            />
          )}
        </div>

        {hostId && <FollowingBorder />}
      </div>
    </section>
  );
}
