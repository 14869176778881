import { Oasis, type OasisResponses } from '@oasis/sdk';
import { Platforms } from '@oasis/utils';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { OasisError } from '~/shared/utils/oasis-error';
import { OasisParams, QueryResultItem } from '~/types';
import { Queries } from '.';

type Data = OasisResponses['Issues']['ListIssues'];

export const issuesQueries = {
  useGetUserPermissions(params: OasisParams<'Issues', 'getUserPermissions'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'issues', 'me'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.getUserPermissions({ ...params, opts: { signal } });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useGetUserPermissions]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },

  useListIssues(params: Omit<OasisParams<'Issues', 'listIssues'>, 'pagination'>) {
    const { projectId, filter, sorting } = params;

    return useInfiniteQuery<Data>({
      queryKey: ['projects', projectId, 'issues', { ...filter, ...sorting }],
      getNextPageParam({ pagination }) {
        const nextOffset = pagination.offset + pagination.limit;
        return nextOffset < pagination.totalResults ? nextOffset : undefined;
      },
      initialPageParam: 0,
      queryFn: async ({ signal, pageParam }) => {
        const offset = typeof pageParam === 'number' ? pageParam : 0;
        const res = await Oasis.Issues.listIssues({ ...params, pagination: { limit: 50, offset } }, { signal });

        if (!res.ok && res.error.code === 'NOT_FOUND' && params.platform === 'bim360') {
          throw new OasisError(
            {
              code: 'NOT_FOUND',
              description: 'Unable to find issues for this project. Please verify that Field Management is enabled.',
            },
            '[Issues.useListIssues]'
          );
        }

        if (!res.ok) {
          throw new OasisError(res.error, '[Issues.useListIssues]');
        }

        return res.value;
      },
    });
  },

  useFindIssueById(params: OasisParams<'Issues', 'findIssueById'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'issues', params.issueId],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.findIssueById(params, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useFindIssueById]');
        return res.value;
      },
    });
  },

  useListIssueTypes(params: OasisParams<'Issues', 'listIssueTypes'> & { opts?: { enabled?: boolean } }) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'issue-types', params.pagination],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.listIssueTypes(params, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useListIssueTypes]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },

  useFindIssueSubtypeById(params: { id?: string; projectId: string; platform: Platforms }) {
    const issueTypes = Queries.Issues.useListIssueTypes({
      projectId: params.projectId,
      platform: params.platform,
    });

    return useQuery({
      queryKey: ['projects', params.projectId, 'issue-types', params.id],
      queryFn: async () => {
        if (!issueTypes.data || !params.id) {
          return null;
        }

        let issueSubtype: QueryResultItem<typeof Queries.Issues.useListIssueTypes>['subtypes'][number] | undefined =
          undefined;

        for (const issueType of issueTypes.data.results) {
          issueSubtype = issueType.subtypes.find(subtype => subtype.id === params.id);
          if (issueSubtype) break;
        }

        return issueSubtype;
      },
      networkMode: 'offlineFirst',
      enabled: Boolean(params.id && issueTypes.data),
    });
  },

  useListIssueTemplates(params: OasisParams<'Issues', 'listIssueTemplates'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'issue-templates'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.listIssueTemplates(params, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useListIssueTemplates]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },

  useListRootCauseCategories(params: OasisParams<'Issues', 'listRootCauseCategories'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'issue-root-cause-categories', params.pagination],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.listRootCauseCategories(params, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useListRootCauseCategories]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },

  useListIssueAttributeDefinitions(params: OasisParams<'Issues', 'listIssueAttributeDefinitions'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'issue-attribute-definitions'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.listIssueAttributeDefinitions(params, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useListIssueAttributeDefinitions]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },

  useListIssueAttributeMappings(params: OasisParams<'Issues', 'listIssueAttributeMappings'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'issue-attribute-mappings'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.listIssueAttributeMappings(params, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useListIssueAttributeMappings]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },

  useListIssueActiveStatuses(params: OasisParams<'Issues', 'listIssueActiveStatuses'>) {
    return useQuery({
      queryKey: ['projects', params.projectId, 'active-statuses'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Issues.listIssueActiveStatuses(params, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Issues.useListIssueActiveStatuses]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },
};
