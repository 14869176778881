import ProgressRing from '@adsk/alloy-react-progress-ring';
import clsx from 'clsx';
import type { ComponentProps } from 'react';
import { useEffect, useRef } from 'react';

interface Props extends ComponentProps<'div'> {
  onIntersect: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  root?: Element | Document | null | undefined;
}

export function IntersectionLoader({ onIntersect, isLoading, disabled, root, ...props }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!ref.current || disabled) {
      return;
    }

    if (!observer.current) {
      observer.current = new IntersectionObserver(
        ([entry]: IntersectionObserverEntry[]) => {
          if (entry?.isIntersecting) {
            onIntersect();
          }
        },
        { root }
      );
    }

    if (!isLoading && observer.current) {
      observer.current.unobserve(ref.current);
      observer.current.observe(ref.current);
    }

    return () => {
      observer.current?.disconnect();
      observer.current = null;
    };
  }, [onIntersect, disabled, isLoading, root]);

  return (
    <div
      ref={ref}
      {...props}
      className={clsx('relative flex items-center justify-center w-full min-h-20 pb-5', props.className)}
    >
      <div className="w-2 h-2 absolute top-0 pointer-events-auto" />
      {isLoading && <ProgressRing size="small" className={clsx(!isLoading && 'hidden')} />}
    </div>
  );
}
