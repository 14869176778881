import type { ManifestChild } from '@oasis/sdk';
import { Oasis } from '@oasis/sdk';
import { FileUtils } from '@oasis/utils';
import { useQuery } from '@tanstack/react-query';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { OasisError } from '~/shared/utils/oasis-error';

export const usersQueries = {
  useListActiveWorkshops(projectId: string) {
    useQuery({
      queryKey: ['@me', 'active', 'workshops'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Users.listActiveWorkshops(projectId, { signal });
        if (!res.ok) throw new OasisError(res.error, '[Users.useListActiveWorkshops]');
        return res.value;
      },
      networkMode: 'always',
      refetchInterval: 1000 * 10,
    });
  },

  useGetActiveViewable(projectId: string, opts?: { enabled?: boolean }) {
    const $session = Oasis.Session.useStore();
    const enabled = !!projectId || opts?.enabled === true;

    return useQuery({
      queryKey: ['@me', 'active', 'model', projectId],
      queryFn: async () => {
        const activeWorkshopId = $session.activeWorkshop?.id;

        if (!activeWorkshopId) {
          return { inProject: false, docVersion: null };
        }

        const workshop = await Oasis.Workshops.getWorkshopSettings(activeWorkshopId);

        if (!workshop.ok) {
          return { inProject: false, docVersion: null };
        }

        // The murn - `{projectId}_{modelId}` is part of the new murnxfrm sequence - `M:urn;T:x,y,z;R:x,y,z,w;S:x,y,z`;
        const murnxfrmValue = workshop.value.murnxfrm?.value;
        // Parse ProjectId and VersionId from murnxfrmValue
        let [versionProjectId, documentVersionId] =
          typeof murnxfrmValue === 'string' ? murnxfrmValue.replace('M:', '').split(/_(.*?)(;|$)/) : ['', ''];

        /**
         * TODO: Supporting both murnxfrm and the new distinct values. Eventually we can remove
         * all of this complete logic to extract values from the delimited string.
         */
        if (typeof workshop.value.prid?.value === 'string') {
          versionProjectId = workshop.value.prid.value;
        }
        if (typeof workshop.value.murn?.value === 'string') {
          documentVersionId = workshop.value.murn.value;
        }

        const inProject = projectId === versionProjectId;

        if (!documentVersionId) {
          return { inProject, docVersion: null };
        }

        const documentVersion = await Oasis.Files.findDocumentVersionById({
          projectId,
          documentVersionId: documentVersionId,
        });

        if (!documentVersion.ok) {
          return { inProject, docVersion: null };
        }

        const docManifest = await Oasis.Files.getModelManifest({
          projectId,
          documentVersionId: documentVersionId,
        });

        let active3dViewGuid = workshop.value.mvid?.value as string | undefined;
        let active3dView: ManifestChild | undefined;
        const is3dView = !!active3dViewGuid;

        if (docManifest.ok) {
          for (const derivative of docManifest.value.derivatives) {
            if (active3dView) {
              break;
            }

            for (const child of derivative.children) {
              if (!active3dViewGuid && child.role === '3d') {
                active3dViewGuid = child.guid;
                active3dView = child;
                break;
              }

              if (child.guid === active3dViewGuid) {
                active3dView = child;
                break;
              }
            }
          }
        }

        if (active3dViewGuid && !active3dView) {
          NotificationManager.push({
            status: 'warning',
            content: 'Unable to find viewable. Has your model changed?',
          });
        }

        // Only persist the active folder urn if it's not already set.
        // We don't want to override if someone has navigated around but then someone else opens a model.
        if (
          !Oasis.Storage.getTemporary('activeFolderUrn') &&
          documentVersion.value.data.attributes.extension.data.originalItemUrn
        ) {
          const document = await Oasis.Files.findDocumentById({
            projectId,
            documentId: documentVersion.value.data.attributes.extension.data.originalItemUrn,
          });

          if (document.ok) {
            Oasis.Storage.setTemporary('activeFolderUrn', document.value.folderId);
          }
        }

        let id = documentVersion.value.data.attributes.extension.data.originalItemUrn ?? undefined;
        let name = documentVersion.value.data.attributes.name;
        let fullName = name;
        let truncatedName = FileUtils.truncateFilenameFromMiddle(name, 20, 8);

        if (is3dView && active3dViewGuid && active3dView) {
          id = active3dViewGuid;
          name = active3dView.name ?? '3d view';
          fullName = `${fullName} - ${name}`;
          truncatedName = FileUtils.truncateFilenameFromMiddle(name, 20, 8);
        }

        return {
          inProject,
          id,
          default3dViewGuid: active3dViewGuid,
          is3dView,
          name,
          fullName,
          truncatedName,
          docVersion: documentVersion.value,
        };
      },
      enabled,
      networkMode: 'always',
      refetchInterval: 1000 * 5,
    });
  },

  useListUsersDevices() {
    return useQuery({
      queryKey: ['@me', 'devices'],
      queryFn: async ({ signal }) => {
        const res = await Oasis.Users.listUsersDevices({ signal });
        if (!res.ok) throw new OasisError(res.error, '[Users.useListUsersDevices]');
        return res.value;
      },
      networkMode: 'offlineFirst',
    });
  },
};
