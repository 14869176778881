import Button from '@adsk/alloy-react-button/es/Button';
import FormField from '@adsk/alloy-react-form-field/es/FormField';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { createIssueAttrs, Oasis, type CreateIssueAttrs } from '@oasis/sdk';
import { DateUtils } from '@oasis/utils';
import { add } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ButtonIcon } from '~/shared/components/base/button';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Tooltip } from '~/shared/components/base/tooltip';
import { TextareaInput } from '~/shared/components/forms/text-area-input';
import { TextInput } from '~/shared/components/forms/text-input';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { QueryResultItem } from '~/types';
import { IssueFieldMapper } from '../issue-field-map';
import { CreateIssueAssignedToField } from './components/assigned-to-field';
import { CreateIssueDateField } from './components/date-field';
import { CreateIssueListField } from './components/list-field';
import { CreateIssueLocationField } from './components/locations-field';
import { CreateIssueRootCauseField } from './components/root-cause-field';
import { CreateIssueStatusField } from './components/status-field';
import { TemplateField } from './components/template-field';
import { CreateIssueTextField } from './components/text-field';
import { CreateIssueTypeField } from './components/type-field';
import { CreateIssueWatchersField } from './components/watchers-field';
import type { IssueTemplate } from './types';

const FORM_NAME = 'create-issue-form';
const DEFAULT_VALUES = {
  title: '',
  status: 'open',
  issueSubtypeId: '',
  published: true,
  watchers: [],
} satisfies CreateIssueAttrs;

type AttributeResultItem = QueryResultItem<typeof Queries.Issues.useListIssueAttributeDefinitions>;

export function CreateIssueModal() {
  const { projectId, platform } = useProjectContext();
  const navigate = useNavigate();
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();

  const form = useForm<CreateIssueAttrs>({
    resolver: zodResolver(createIssueAttrs),
    defaultValues: { ...DEFAULT_VALUES },
  });
  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState<IssueTemplate | null>(null);

  const userPermissions = Queries.Issues.useGetUserPermissions({ projectId, platform });
  const attributeDefinitions = Queries.Issues.useListIssueAttributeDefinitions({ projectId, platform });
  const activeViewable = Queries.Users.useGetActiveViewable(projectId);
  const createIssue = Mutations.Issues.useCreateIssue();

  useEffect(() => {
    if (!open) {
      setTemplate(null);
      form.reset({ ...DEFAULT_VALUES });
    }
  }, [open, form]);

  const attributesLookup = useMemo(() => {
    const lookup = new Map<string, AttributeResultItem>();

    if (attributeDefinitions.data?.results) {
      for (const customAttribute of attributeDefinitions.data.results) {
        lookup.set(customAttribute.id, customAttribute);
      }
    }

    return lookup;
  }, [attributeDefinitions.data]);

  function handleChangeTemplate(template: IssueTemplate) {
    setTemplate(template);
    const { assigneeId, daysTilDueDate, ...templateValues } = template.issueAttributes;

    form.reset({
      ...form.getValues(),
      ...templateValues,
      assignedTo: assigneeId,
      dueDate: typeof daysTilDueDate === 'number' ? add(new Date(), { days: daysTilDueDate }).toISOString() : undefined,
    });
  }

  function setCustomAttribute(value: { attributeDefinitionId: string; value: string | number | null }) {
    const existingValue = form.getValues().customAttributes ?? [];

    form.setValue(
      'customAttributes',
      existingValue.map(customAttribute => {
        return customAttribute.attributeDefinitionId === value.attributeDefinitionId ? value : customAttribute;
      })
    );
  }

  function handleSubmit(values: CreateIssueAttrs) {
    createIssue.mutate(
      {
        projectId,
        platform,
        attrs: {
          ...values,
          dueDate: values.dueDate ? DateUtils.getDateAsValue(values.dueDate) : undefined,
          startDate: values.startDate ? DateUtils.getDateAsValue(values.startDate) : undefined,
        },
      },
      {
        onSuccess(data) {
          setOpen(false);

          NotificationManager.push({
            status: 'success',
            content: 'Successfully created a new issue.',
          });

          navigate(`/projects/${projectId}/issues?issueId=${data.id}`);
        },
      }
    );
  }

  function handleCreateIssueButton() {
    if (Oasis.Env.store.isVr) {
      Oasis.NetworkCommands.createIssueOnDevice(projectId);
    } else {
      setOpen(true);
    }
  }

  const cannotCreate = !userPermissions.data?.permissionLevels.includes('create');
  const issueSubtypeId = form.watch('issueSubtypeId');
  const status = form.watch('status');
  const customAttributes = form.watch('customAttributes');
  const noModelInVr = $env.isVr && !activeViewable.data?.docVersion;

  return (
    <>
      <Tooltip
        className="mb-2"
        content={
          noModelInVr
            ? 'Please load a model to create an issue.'
            : $session.activeVrTool === 'create-issue'
              ? 'Create issue tool active. Use controllers to place an issue.'
              : userPermissions.isLoading
                ? 'Checking permissions...'
                : !userPermissions.data
                  ? 'Unable to determine your permissions.'
                  : cannotCreate
                    ? 'You do not have permission to create issues.'
                    : undefined
        }
      >
        <Button
          variant="primary"
          onClick={handleCreateIssueButton}
          disabled={
            userPermissions.isLoading || cannotCreate || $session.activeVrTool === 'create-issue' || noModelInVr
          }
        >
          <ButtonIcon icon="plus" position="left" />
          Create issue
        </Button>
      </Tooltip>

      {!$env.isVr && (
        // @ts-expect-error
        <Modal open={open} className="!h-auto" width={576}>
          <Modal.Header>
            <h2>Create Issue</h2>
            <Modal.Close showEscapeLabel={false} onClick={() => setOpen(false)} />
          </Modal.Header>
          <Modal.Body className="!pt-6 !pb-10 max-h-[680px]">
            <form id={FORM_NAME} onSubmit={form.handleSubmit(handleSubmit)} className="min-h-[300px] space-y-6">
              {platform === 'acc' && (
                <TemplateField
                  value={template?.id || ''}
                  displayValue={template?.issueAttributes.title}
                  onChange={handleChangeTemplate}
                />
              )}

              <Controller
                control={form.control}
                name="title"
                render={({ field: { ref: _, ...field }, fieldState }) => (
                  <FormField label="Title" labelVariant="top" required error={fieldState.error?.message}>
                    <TextInput {...field} error={!!fieldState.error} />
                  </FormField>
                )}
              />

              <Controller
                control={form.control}
                name="status"
                render={({ field: { ref: _, ...field }, fieldState }) => (
                  <FormField label="Status" labelVariant="top" required error={fieldState.error?.message}>
                    <CreateIssueStatusField {...field} onChange={value => form.setValue('status', value)} />
                  </FormField>
                )}
              />

              <Controller
                control={form.control}
                name="issueSubtypeId"
                render={({ fieldState }) => (
                  <FormField label="Type" labelVariant="top" required error={fieldState.error?.message}>
                    <CreateIssueTypeField
                      issueSubtypeId={issueSubtypeId}
                      status={status}
                      onChange={value => form.setValue('issueSubtypeId', value)}
                    />
                  </FormField>
                )}
              />

              <IssueFieldMapper
                issueSubtypeId={issueSubtypeId}
                renderField={issueField => {
                  switch (issueField.id) {
                    case 'description':
                      return (
                        <Controller
                          control={form.control}
                          name="description"
                          render={({ field: { ref: _, ...field } }) => (
                            <FormField label="Description" labelVariant="top" required={issueField.required}>
                              <TextareaInput placeholder="Describe the issue" {...field} />
                            </FormField>
                          )}
                        />
                      );

                    case 'assignedTo':
                      return (
                        <Controller
                          control={form.control}
                          name="assignedTo"
                          render={({ field: { ref: _, ...field } }) => (
                            <CreateIssueAssignedToField
                              {...field}
                              onChange={value => {
                                form.setValue('assignedToType', value.type);
                                form.setValue('assignedTo', value.id);

                                const existingWatchers = form.getValues().watchers;

                                if (!existingWatchers) {
                                  form.setValue('watchers', [value.id]);
                                } else if (!existingWatchers.includes(value.id)) {
                                  form.setValue('watchers', [...existingWatchers, value.id]);
                                }
                              }}
                              required={issueField.required}
                            />
                          )}
                        />
                      );

                    case 'watchers':
                      return (
                        <Controller
                          control={form.control}
                          name="watchers"
                          render={({ field: { ref: _, ...field } }) => (
                            <CreateIssueWatchersField
                              watchers={field.value ?? []}
                              onChange={value => form.setValue('watchers', value)}
                              required={issueField.required}
                            />
                          )}
                        />
                      );

                    case 'locationDetails':
                      return (
                        <Controller
                          control={form.control}
                          name="locationDetails"
                          render={({ field: { ref: _, ...field } }) => (
                            <FormField label="Location Details" labelVariant="top" required={issueField.required}>
                              <TextInput placeholder="Enter location details" {...field} />
                            </FormField>
                          )}
                        />
                      );

                    case 'locationId':
                      // TODO: bim locations are throwing CORS exceptions
                      if (platform === 'bim360') break;

                      return (
                        <Controller
                          control={form.control}
                          name="locationId"
                          render={({ field: { ref: _, ...field } }) => (
                            <CreateIssueLocationField
                              {...field}
                              onChange={value => form.setValue('locationId', value)}
                              required={issueField.required}
                            />
                          )}
                        />
                      );

                    case 'rootCauseId':
                      return (
                        <Controller
                          control={form.control}
                          name="rootCauseId"
                          render={({ field: { ref: _, ...field } }) => (
                            <CreateIssueRootCauseField
                              {...field}
                              onChange={value => form.setValue('rootCauseId', value)}
                              required={issueField.required}
                            />
                          )}
                        />
                      );

                    case 'startDate':
                      return (
                        <Controller
                          control={form.control}
                          name="startDate"
                          render={({ field: { ref: _, ...field } }) => (
                            <CreateIssueDateField
                              {...field}
                              name="start-date"
                              label="Start date"
                              onChange={value => form.setValue('startDate', value)}
                              required={issueField.required}
                            />
                          )}
                        />
                      );

                    case 'dueDate':
                      return (
                        <Controller
                          control={form.control}
                          name="dueDate"
                          render={({ field: { ref: _, ...field } }) => (
                            <CreateIssueDateField
                              {...field}
                              name="due-date"
                              label="Due date"
                              onChange={value => form.setValue('dueDate', value)}
                              required={issueField.required}
                            />
                          )}
                        />
                      );

                    default:
                      return null;
                  }
                }}
              />

              {attributeDefinitions.data &&
                customAttributes?.map(attribute => {
                  const attributeDefinition = attributesLookup.get(attribute.attributeDefinitionId);

                  if (!attributeDefinition) {
                    return null;
                  }

                  return (
                    <div key={attribute.attributeDefinitionId}>
                      {(attributeDefinition.dataType === 'text' ||
                        attributeDefinition.dataType === 'numeric' ||
                        attributeDefinition.dataType === 'paragraph') && (
                        <CreateIssueTextField
                          type={attributeDefinition.dataType !== 'paragraph' ? attributeDefinition.dataType : undefined}
                          name={attributeDefinition.title}
                          label={attributeDefinition.title}
                          defaultValue={attribute.value ? String(attribute.value) : null}
                          onChange={value =>
                            setCustomAttribute({ attributeDefinitionId: attribute.attributeDefinitionId, value })
                          }
                          textarea={attributeDefinition.dataType === 'paragraph'}
                        />
                      )}

                      {attributeDefinition.dataType === 'list' && attributeDefinition.metadata.list && (
                        <CreateIssueListField
                          value={attribute.value}
                          options={attributeDefinition.metadata.list.options}
                          name={attributeDefinition.title}
                          label={attributeDefinition.title}
                          onChange={value =>
                            setCustomAttribute({ attributeDefinitionId: attribute.attributeDefinitionId, value })
                          }
                        />
                      )}
                    </div>
                  );
                })}
            </form>
          </Modal.Body>
          <Modal.Footer className="flex gap-1">
            <Button variant="tertiary" onClick={() => setOpen(false)}>
              Cancel
            </Button>

            <Button variant="primary" type="submit" loading={createIssue.isPending} form={FORM_NAME}>
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
