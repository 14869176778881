import { Platforms, ProjectUtils } from '@oasis/utils';
import { HTTPError, Options } from 'ky';
import { Err, Ok } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { ApsHttp } from '../../providers/http/aps-http.provider';
import { Segment } from '../../providers/segment/segment.provider';
import { Files } from '../files/files.service';
import { ListIssuesConst } from './issues.const';
import { IssuesSchemas } from './issues.schemas';
import {
  CreateIssueAttrs,
  ListIssueTypesParams,
  ListIssuesParams,
  ListRootCauseCategories,
  UpdateIssueParams,
  type IssuePlacement,
} from './issues.types';

const _parse = HttpUtils.createScopedParser('Issues', IssuesSchemas);

export const Issues = {
  /**
   * @name getUserPermissions
   * Returns the current issues user permissions.
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-users-me-GET/
   */
  async getUserPermissions(params: { projectId: string; platform: Platforms; opts?: Options }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'users/me')
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'users/me');

      const res = await ApsHttp.client.get(url, params?.opts).json();
      const data = _parse('getUserPermissions', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.getUserPermissions]');
    }
  },

  /**
   * @name createIssue
   * Adds an issue to a project.
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issues-POST/
   */
  async createIssue(params: { projectId: string; platform: Platforms; attrs: CreateIssueAttrs }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issues')
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issues');

      const body = JSON.stringify({
        published: true,
        title: params.attrs.title,
        issueSubtypeId: params.attrs.issueSubtypeId,
        status: params.attrs.status,
        description: params.attrs.description,
        snapshotUrn: params.attrs.snapshotUrn,
        assignedTo: params.attrs.assignedTo,
        assignedToType: params.attrs.assignedToType,
        dueDate: params.attrs.dueDate,
        startDate: params.attrs.startDate,
        locationId: params.attrs.locationId,
        locationDetails: params.attrs.locationDetails,
        rootCauseId: params.attrs.rootCauseId,
        watchers: params.attrs.watchers || [],
      });

      const res = await ApsHttp.client.post(url, { body }).json();
      const data = _parse('createIssue', res);

      Segment.track('Issue Created', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.createIssue]');
    }
  },

  /**
   * @name listIssues
   * List/filter issues.
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issues-GET/
   */
  async listIssues(params: ListIssuesParams, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const limit = String(params.pagination?.limit || 50);
      const offset = String(params.pagination?.offset || 0);
      const sortBy = params.sorting?.length
        ? params.sorting.map(({ id, desc }) => (desc ? `-${id}` : id)).join(',')
        : '-displayId';

      const searchParams = new URLSearchParams();
      searchParams.set('limit', limit);
      searchParams.set('offset', offset);
      searchParams.set('sortBy', sortBy);

      if (params.platform === 'acc') {
        searchParams.set('filter[valid]', 'true');
      }

      if (params.filter?.linkedModelUrn) {
        searchParams.set('filter[linkedDocumentUrn]', params.filter.linkedModelUrn);
      }

      // Set string filters when present
      for (const key of ListIssuesConst.StringFilterKeys) {
        const value = params.filter?.[key];
        if (value) searchParams.set('filter[' + key + ']', value);
      }

      // Encode boolean and number filters
      for (const key of ListIssuesConst.NonStringFilterKeys) {
        const value = params.filter?.[key];
        if (value) searchParams.set(key, encodeURIComponent(value));
      }

      // Join filter arrays into comma separated strings
      for (const key of ListIssuesConst.ArrayFilterKeys) {
        const value = params.filter?.[key]?.join(',');
        if (value) searchParams.set(key, value);
      }

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issues')
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issues');

      const res = await ApsHttp.client.get(url, { searchParams, ...opts }).json();
      const data = _parse('listIssues', res);

      // Get the placement docs too
      const placementUrns = new Set<string>();

      for (const issue of data.results) {
        const urn = issue.linkedDocuments[0]?.urn;
        if (urn) placementUrns.add(urn);
      }

      const linkedDocs = await Files.listDocumentsById({ ...params, urns: Array.from(placementUrns) });
      const placementLookup = new Map<string, IssuePlacement>();

      if (linkedDocs.ok) {
        for (const doc of linkedDocs.value) {
          placementLookup.set(doc.id, { urn: doc.id, name: doc.displayName });
        }
      }

      const results = data.results.map(issue => {
        const placementDoc = issue.linkedDocuments[0];
        const placement = placementDoc ? placementLookup.get(placementDoc.urn) : undefined;
        const placementViewableGuid = placementDoc?.details?.viewable?.guid ?? undefined;

        return {
          ...issue,
          placement,
          placementViewableGuid,
        };
      });

      return Ok({ ...data, results });
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.listIssues]');
    }
  },

  /**
   * @name findIssueById
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issues-issueId-GET/
   */
  async findIssueById(params: { projectId: string; platform: Platforms; issueId: string }, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issues', params.issueId)
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issues', params.issueId);

      const res = await ApsHttp.client.get(url, opts).json();
      const data = _parse('findIssueById', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.findIssueById]');
    }
  },

  /**
   * @name updateIssue
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issues-issueId-PATCH/
   */
  async updateIssue(params: UpdateIssueParams) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      if ('issueTypeId' in params.attrs) {
        delete params.attrs.issueTypeId;
      }

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issues', params.issueId)
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issues', params.issueId);

      const res = await ApsHttp.client.patch(url, { body: JSON.stringify(params.attrs) }).json();
      const data = _parse('updateIssue', res);

      Segment.track('Issue Updated', params);

      return Ok(data);
    } catch (error) {
      if (error instanceof HTTPError && error.response.status === 400) {
        const errorBody = await error.response.json();
        return Err({ code: 'ERR_MESSAGE', message: errorBody.details });
      }
      return HttpUtils.handleError(error, '[Issues.findIssueById]');
    }
  },

  /**
   * @name deleteIssue
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issues-issueId-PATCH/
   */
  async deleteIssue(params: { projectId: string; issueId: string }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const url = ApsHttp.path('acc', 'issues/v1', projectId, 'issues', params.issueId);

      const res = await ApsHttp.client.delete(url).json();
      const data = _parse('deleteIssue', res);

      Segment.track('Issue Deleted', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.deleteIssue]');
    }
  },

  async listIssueTypes(params: ListIssueTypesParams, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('include', 'subtypes');
      searchParams.set('limit', String(params.pagination?.limit || 9999));
      searchParams.set('offset', String(params.pagination?.offset || 0));

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issue-types')
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issue-types');

      const res = await ApsHttp.client.get(url, { ...opts, searchParams }).json();
      const data = _parse('listIssueTypes', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.findIssueById]');
    }
  },

  /**
   * @name listRootCauseCategories
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issue-root-cause-categories-GET/
   */
  async listRootCauseCategories(params: ListRootCauseCategories, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('include', 'rootcauses');
      searchParams.set('limit', String(params.pagination?.limit || 9999));
      searchParams.set('offset', String(params.pagination?.offset || 0));

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issue-root-cause-categories')
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issue-root-cause-categories');

      const res = await ApsHttp.client.get(url, { ...opts, searchParams }).json();
      const data = _parse('listRootCauseCategories', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.listRootCauseCategories]');
    }
  },

  async listIssueAttributeDefinitions(params: { projectId: string; platform: Platforms }, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('limit', '9999');
      searchParams.set('offset', '0');

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issue-attribute-definitions')
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issue-attribute-definitions');

      const res = await ApsHttp.client.get(url, { ...opts, searchParams }).json();
      const data = _parse('listIssueAttributeDefinitions', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.listIssueAttributeDefinitions]');
    }
  },

  /**
   * @name listIssueAttributeMappings
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issue-attribute-mappings-GET
   */
  async listIssueAttributeMappings(params: { projectId: string; platform: Platforms }, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('limit', '9999');
      searchParams.set('offset', '0');

      const url =
        params.platform === 'acc'
          ? ApsHttp.path('acc', 'issues/v1', projectId, 'issue-attribute-mappings')
          : ApsHttp.path('bim360', 'issues/v2', projectId, 'issue-attribute-mappings');

      const res = await ApsHttp.client.get(url, { ...opts, searchParams }).json();
      const data = _parse('listIssueAttributeMappings', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.listIssueAttributeMappings]');
    }
  },

  /**
   * @name listIssueActiveStatuses
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-active-statuses-GET/
   */
  async listIssueActiveStatuses(params: { projectId: string }, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const url = ApsHttp.path('acc', 'issues/v1', projectId, 'active-statuses');

      const res = await ApsHttp.client.get(url, opts).json();
      const data = _parse('listIssueActiveStatuses', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.listIssueActiveStatuses]');
    }
  },

  async listIssueTemplates(params: { projectId: string }, opts?: Options) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('limit', '9999');

      const url = ApsHttp.path('acc', 'issues/v1', projectId, 'issue-templates');
      const res = await ApsHttp.client.get(url, { ...opts, searchParams }).json();
      const data = _parse('listIssueTemplates', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Issues.listIssueTemplates]');
    }
  },
};
